import Error from 'fitify-ui-landing/src/components/blocks/ErrorPage/ErrorPage'
import { NextPage } from 'next'
import { useTranslation } from 'next-i18next'

import Layout from '@/components/layouts/Layout'
import { getStaticPropsTranslations } from '@/utils/i18n'

const Error404Page: NextPage = () => {
  const { t } = useTranslation()

  return (
    <Layout
      seo={{
        title: t('hc_landing_error_message_404') + ' | FITIFY',
      }}
    >
      <Error
        errorTitle="hc_landing_error_message_404"
        errorDescription="hc_landing_error_message_title"
        errorParagraph="hc_landing_error_message_subtitle"
        errorCta="hc_landing_error_message_cta"
      />
    </Layout>
  )
}

export default Error404Page

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await getStaticPropsTranslations(locale)),
    },
  }
}
